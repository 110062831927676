var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"secondary white--text"},[_c('div',{staticClass:"ma-0"},[(_vm.reconciledPayments)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"large":"","left":"","color":"white"}},[_vm._v("mdi-clipboard-check-multiple-outline")]),_c('p',{staticClass:"subtitle-1 my-0"},[_vm._v(_vm._s(_vm.reconciledPayments.Count)+" payments")]),_c('v-divider',{staticClass:"mx-3 my-1",attrs:{"vertical":"","color":"white"}}),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.totalPayments))])],1):_vm._e()]),_c('v-spacer'),_c('TableSearch',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"tableColor",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.reconciledPayments.Data,"item-key":"PaymentGrouptID","items-per-page":10},scopedSlots:_vm._u([{key:"item.CreatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.CreatedAt))+" ")])]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("returnDisplayAmount")(item))+" ")])]}},{key:"item.FinanceEntries",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","color":"#03A9F4","dark":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-find")])],1)]}},{key:"item.Message",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.openMsgDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-text-outline")])],1)]}}])})],1),_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text",attrs:{"primary-title":""}},[_vm._v(" Finance Entries "),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v("Back ")],1)],1),_c('v-card-text',[_c('FinanceEntryTable',{attrs:{"records":this.selectedItem.FinanceEntries}})],1)],1)],1),_c('MsgDialog',{attrs:{"dialog":_vm.msgDialog,"details":_vm.selectedItem.Details,"source":_vm.source,"OID":_vm.selectedItem.PaymentGroupID},on:{"closeDialog":_vm.closeMsgDialog,"msgSent":_vm.msgSent}}),_c('Snackbar',{attrs:{"snackbarDialog":_vm.snackbar,"snackbarText":_vm.snackbarText,"snackbarColor":_vm.snackbarColor,"timeout":_vm.timeout},on:{"closeSnackbar":function($event){return _vm.closeSnackbar()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }