<template>
  <div class="">
    <!-- <CircularOverlay :overlay="!unreconciledPaymentsRecords" /> -->
    <v-container class="py-0">
      <transition name="fade" mode="out-in" appear>
        <PageTitle class="d-block d-sm-none">Client Accounts</PageTitle>
      </transition>

      <transition name="slide-left-fade" mode="out-in" appear>
        <v-tabs
          v-model="tab"
          class="mt-5 mt-sm-1"
          color="tabColor"
          slider-color="tabcolor"
          center-active
          background-color="tertiary"
          show-arrows
          height="55"
        >
          <v-tab ripple> Unreconciled </v-tab>
          <v-tab ripple> Reconciled </v-tab>
          <v-tab ripple> Payment Requests </v-tab>
          <v-spacer></v-spacer>
          <span class="d-none d-sm-block">
            <PageTitle>Client accounts</PageTitle>
          </span>
        </v-tabs>
      </transition>

      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <v-tabs-items class="pt-2 tertiary" v-model="tab">
            <v-tab-item>
              <Skeleton v-if="!unreconciledPayments" />
              <Unreconciled v-if="unreconciledPayments" />
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!reconciledPayments" />
              <Reconciled v-if="reconciledPayments" />
            </v-tab-item>
            <v-tab-item>
               <GenericRequestsTable />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </transition>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Unreconciled from "../components/Unreconciled/UnreconciledPaymentRecords.vue";
import Reconciled from "../components/Unreconciled/ReconciledPaymentRecords.vue";
import Skeleton from "../components/SkeletonLoaders/SkeletonLoaderTable.vue";
import PageTitle from "@/components/Slots/PageTitle.vue";
import GenericRequestsTable from "@/components/PaymentRequests/GenericRequestsTable.vue";

export default {
  components: {
    Unreconciled,
    Reconciled,
    Skeleton,
    PageTitle,
    GenericRequestsTable,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    this.$store.dispatch("fetchUnreconciledsPaymentsData");
  },
  computed: {
    ...mapState({
      unreconciledPayments: (state) =>
        state.UnreconciledPayments.unreconciledPaymentsRecords,
      reconciledPayments: (state) =>
        state.UnreconciledPayments.reconciledPaymentsRecords,
    }),
  },
};
</script>
