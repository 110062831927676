<template lang="html">
  <div>
    <v-card flat>
      <v-card-title class="secondary white--text">
        <div class="ma-0">
          <div v-if="reconciledPayments" class="d-flex align-center">
            <v-icon large left color="white">mdi-clipboard-check-multiple-outline</v-icon>
            <p class="subtitle-1 my-0">{{ reconciledPayments.Count }} payments</p>
            <v-divider vertical class="mx-3 my-1" color="white"></v-divider>
            <span class="subtitle-1">{{ totalPayments }}</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <TableSearch v-model="search" />
      </v-card-title>

      <v-data-table
        class="tableColor"
        :search="search"
        :headers="headers"
        :items="reconciledPayments.Data"
        item-key="PaymentGrouptID"
        :items-per-page="10"
      >
        <template v-slot:item.CreatedAt="{ item }">
          <span>
            {{ item.CreatedAt | formatDate }}
          </span>
        </template>

        <template v-slot:item.Amount="{ item }" >
          <span>
            {{ item | returnDisplayAmount}}
          </span>
        </template>

        <template v-slot:item.FinanceEntries="{ item }" >
          <v-btn outlined small color="#03A9F4" dark @click="openDialog(item)">
            <v-icon small>mdi-file-find</v-icon></v-btn>
          <!-- <v-btn color="success" @click="openDialog(item)">View entries</v-btn> -->
        </template>

        <!-- Btn to open Message dialog -->
        <template v-slot:item.Message="{ item }" >
          <v-btn @click="openMsgDialog(item)" text small outlined color="secondary">
            <v-icon small>mdi-comment-text-outline</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </v-card>

    <!-- Full records dialog -->
    <v-dialog
      v-model="dialog"
      fullscreen 
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="secondary white--text" primary-title>
          Finance Entries
          <v-spacer></v-spacer>
          <v-btn @click.stop="closeDialog()" outlined color="white">
            <v-icon>mdi-arrow-left</v-icon>Back
          </v-btn>
        </v-card-title>
        <v-card-text>
          <FinanceEntryTable 
            :records="this.selectedItem.FinanceEntries"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <MsgDialog
      :dialog="msgDialog"
      :details="selectedItem.Details"
      :source="source"
      :OID="selectedItem.PaymentGroupID"
      @closeDialog="closeMsgDialog"
      @msgSent="msgSent"
    />

    <Snackbar 
      :snackbarDialog="snackbar" 
      :snackbarText="snackbarText" 
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar()"
      :timeout="timeout"
    />
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FinanceEntryTable from './ReconciledPaymentsFinanceEntriesTable'
import MsgDialog from '@/components/Dialogs/MessageDialog'
import Snackbar from '@/components/Snackbars/TheSnackbar'
import TableSearch from '@/components/TableComponents/TableSearch.vue'

export default {
  components: {
    FinanceEntryTable,
    MsgDialog,
    Snackbar,
    TableSearch
  },
  data() {
    return {
      source: 'Reconciled payments',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
      dialog: false,
      msgDialog: false,
      loading: false,
      selectedItem: '',
      search: '',
      headers: [
      { text: 'Amount', align: 'left', value: 'Amount', sortable: true, sort: (a, b) => b - a },
      { text: 'Date', value: 'CreatedAt' },
      { text: 'Details', value: 'Details' },
      { text: 'State', value: 'State' },
      { text: 'Reference', value: 'Reference' },
      { text: 'Finance entries', value: 'FinanceEntries',},
      { text: 'Support', value: 'Message' }
    ],
    }
  },
  methods: {
    getColor(color) {
     return color.toLowerCase() + '--text'
    },
    openDialog(item) {
      this.selectedItem = item
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.selectedItem = ''
    },
    openMsgDialog(item) {
      this.selectedItem = item
      this.msgDialog = true
    },
    closeMsgDialog() {
      this.msgDialog = false
      this.selectedItem = ''
    },
    msgSent(payload) {
      this.msgDialog = false
      this.snackbar = true,
      this.snackbarColor = payload.color
      this.snackbarText = payload.text
    },
  },
  computed: {
    ...mapState({
      reconciledPayments: state => state.UnreconciledPayments.reconciledPaymentsRecords
    }),
    totalPayments() {
      const array = this.reconciledPayments.Data
      const payments = array.map(val => {
        return val.Amount
      })
      const total = payments.reduce(
        (acc, val) => acc + val,
        0
      )
      // return total.toLocaleString()
      return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(total)
    },
  },
  filters: {
    returnDisplayAmount(val) {
      return val.DisplayAmount
    }
  },

}
</script>
