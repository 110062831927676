<template>
  <div>
    <v-card outlined class="mt-5">
      <v-card-title class="primary white--text">
        Total entries: {{records.length }}
      </v-card-title>
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="10"
          class="elevation-1 tableColor"
        >

          <template v-slot:item.Amount="{ item }" >
            <span>
              {{ item | returnDisplayAmount }} 
            </span>
          </template>
        </v-data-table>
    </v-card>
    
  </div>
</template>

<script>
export default {
  props: ['records'],
  data() {
    return {
      dialog: false,
      selectedItem: '',
      headers: [
        {
          text: 'Source acc.',
          align: 'left',
          value: 'SourceAccount',
        },
        { text: 'Target account', value: 'TargetAccount' },
        { text: 'Info', value: 'Name' },
        { text: 'Payment type', value: 'PaymentType' },
        { text: 'Amount', value: 'Amount', sortable: true, sort: (a, b) => b - a },
      ],
    }
  },
  methods: {
    openDialog(item) {
      this.dialog = true
      this.selectedItem = item
    }
  },
  filters: {
    returnDisplayAmount(val) {
      return val.DisplayAmount
    }
  },
}
</script>

<style>

</style>