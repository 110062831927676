<template lang="html">
  <div>
    <v-card flat>
      <v-card-title class="secondary white--text">
        <div class="ma-0">
          <div v-if="unreconciledPaymentsRecords" class="d-flex align-center">
            <v-icon large left color="white">mdi-transfer</v-icon>
            <p class="subtitle-1 my-0">{{ unreconciledPaymentsRecords.Count }} payments</p>
            <v-divider vertical class="mx-3 my-1" color="white"></v-divider>
            <span class="subtitle-1">{{ totalPayments }}</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <TableSearch @input="(newText) => {search = newText}" />
      </v-card-title>

      <v-data-table
        class="tableColor"
        :search="search"
        :headers="headers"
        :items="unreconciledPaymentsRecords.Data"
        :items-per-page="10"
        sort-by="StatementReference"
      >
        <!-- Payment date formatted -->
        <template v-slot:item.PaymentDate="{ item }">
          <span>
            {{ item.PaymentDate | formatDate }}
          </span>
        </template>
        
        <!-- Payment amount color returned -->
        <template v-slot:item.PaymentAmount="{ item }" >
          <span :class="getColor(item.ColourStatus)">
            {{ item | returnDisplayAmount}}
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu  offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                small
                depressed
                v-bind="attrs"
                v-on="on"
                class="text-capitalize"
              >
                Actions
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0 secondary" dark>
              <v-list-item @click="openDialog(item)">
                <v-list-item-title>Contact support regarding this payment</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-comment-text-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <MsgDialog
      :dialog="dialog"
      :details="selectedItem.DisplayPaymentAmount"
      :source="source"
      :OID="selectedItem.OID"
      @closeDialog="closeDialog"
      @msgSent="msgSent"
    />

    <Snackbar 
      :snackbarDialog="snackbar" 
      :snackbarText="snackbarText" 
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar()"
      :timeout="timeout"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex'
import MsgDialog from '@/components/Dialogs/MessageDialog'
import Snackbar from '@/components/Snackbars/TheSnackbar'
import TableSearch from '@/components/TableComponents/TableSearch.vue'

export default {
  components: {
    MsgDialog,
    Snackbar,
    TableSearch
  },
  data() {
    return {
      source: 'Unreconciled payment',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
      loading: false,
      search: '',
      selectedItem: '',
      dialog: false,
      msgText: '',
      headers: [
      {
        text: 'Bank Statement Ref.',
        align: 'left',
        value: 'StatementReference',
        sortable: true,
      },
      { text: 'Date', value: 'PaymentDate' },
      { text: 'Amount', value: 'PaymentAmount', sortable: true, sort: (a, b) => b - a },
      // { text: 'Support', value: 'Message' },
      { text: '', value: 'actions', sortable: false, align: 'end' }
    ],
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false
    },
    openDialog(item) {
      this.selectedItem = item
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.msgText = ''
    },
    msgSent(payload) {
      this.dialog = false
      this.snackbar = true,
      this.snackbarColor = payload.color
      this.snackbarText = payload.text
    },
    getColor(color) {
     return color.toLowerCase() + '--text font-weight-medium'
    },
  },
  computed: {
    ...mapState({
      unreconciledPaymentsRecords: state => state.UnreconciledPayments.unreconciledPaymentsRecords
    }),
    totalPayments() {
      const array = this.unreconciledPaymentsRecords.Data
      const payments = array.map(val => {
        return val.PaymentAmount
      })
      const total = payments.reduce(
        (acc, val) => acc + val,
        0
      )
      // return total.toLocaleString()
      return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(total)
    },
  },
  filters: {
    returnDisplayAmount(val) {
      return val.DisplayPaymentAmount
    }
  },

}
</script>





