var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"secondary white--text"},[_c('div',{staticClass:"ma-0"},[(_vm.unreconciledPaymentsRecords)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"large":"","left":"","color":"white"}},[_vm._v("mdi-transfer")]),_c('p',{staticClass:"subtitle-1 my-0"},[_vm._v(_vm._s(_vm.unreconciledPaymentsRecords.Count)+" payments")]),_c('v-divider',{staticClass:"mx-3 my-1",attrs:{"vertical":"","color":"white"}}),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.totalPayments))])],1):_vm._e()]),_c('v-spacer'),_c('TableSearch',{on:{"input":function (newText) {_vm.search = newText}}})],1),_c('v-data-table',{staticClass:"tableColor",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.unreconciledPaymentsRecords.Data,"items-per-page":10,"sort-by":"StatementReference"},scopedSlots:_vm._u([{key:"item.PaymentDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.PaymentDate))+" ")])]}},{key:"item.PaymentAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColor(item.ColourStatus)},[_vm._v(" "+_vm._s(_vm._f("returnDisplayAmount")(item))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"secondary","dark":"","small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0 secondary",attrs:{"dark":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-list-item-title',[_vm._v("Contact support regarding this payment")]),_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-text-outline")])],1)],1)],1)],1)]}}])})],1),_c('MsgDialog',{attrs:{"dialog":_vm.dialog,"details":_vm.selectedItem.DisplayPaymentAmount,"source":_vm.source,"OID":_vm.selectedItem.OID},on:{"closeDialog":_vm.closeDialog,"msgSent":_vm.msgSent}}),_c('Snackbar',{attrs:{"snackbarDialog":_vm.snackbar,"snackbarText":_vm.snackbarText,"snackbarColor":_vm.snackbarColor,"timeout":_vm.timeout},on:{"closeSnackbar":function($event){return _vm.closeSnackbar()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }